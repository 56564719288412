import React, { useState } from 'react';
import firebase from 'firebase/app';
import { Button, TextField, Paper, Typography } from '@material-ui/core';
import Layout, { Wrapper } from '../layouts/Layout';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { Branding } from '../components/Branding';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { useSnackbar } from '../hooks/useSnackbar';
import { ErrorMessage } from '../components/Alerts';
import { InlineLoader } from '../components/Loader';

const Card = styled('div')`
  padding: 48px;
  margin: 24px auto;
`;

const ResetPasswordForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  return (
    <Card>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setLoading(true);

          firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
              setLoading(false);
              setErrorMsg('');
              enqueueSnackbar(`A password reset email has been sent!`, {
                variant: 'success'
              });
            })
            .catch((err) => {
              setLoading(false);
              setErrorMsg(err.message);
              console.log(err);
            });
        }}
      >
        <TextField
          variant="outlined"
          label="Email address"
          name="email"
          type="email"
          id="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ padding: '14px 24px' }}
          disabled={loading}
          size="large"
        >
          {loading && <InlineLoader color="inherit" />}&nbsp;
          {loading ? 'Resetting your password...' : 'Reset Password'}
        </Button>
      </form>
      <br />
      {errorMsg && (
        <ErrorMessage>
          <strong>An error occurred.</strong> {errorMsg}
        </ErrorMessage>
      )}
    </Card>
  );
};

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Reset Password"
        siteUrl="https://bloggingfordevs.com"
        description="Reset Password"
        pathname="reset-password/"
      />
      <ProductHuntBanner />
      <div
        style={{
          maxWidth: '500px',
          padding: '16px',
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <Wrapper>
          <Branding to="/">Blogging for Devs</Branding>
          <Typography variant="h4" component="h1" gutterBottom>
            Reset Your Password
          </Typography>
          <ResetPasswordForm />
        </Wrapper>
      </div>
    </Layout>
  );
};

export default IndexPage;
